<template>
  <div id="menu-service" class="m-portlet margin-left-0">
    <div
      class="m-widget14__header m-widget14- text-center pt-4 pb-4 border-bottom"
    >
      <h4 class="m-widget14__title">
        {{ !service.name ? "..." : service.name }}
      </h4>
      <div class="m-widget14__desc">
        Última Atualização
        <br />
        <span v-if="service.updated_at">{{
          $helper.convertDatePtBr(service.updated_at, true)
        }}</span>
        <br />
        <br />
        <span v-if="service.status">
          <span
            :class="`m-badge m-badge--${
              support.registerBadge(service.status_register).color
            } m-badge--wide`"
            >{{ support.registerBadge(service.status_register).name }}</span
          >
        </span>
      </div>
    </div>
    <div class="m-portlet__body p-0">
      <!--begin::Section-->
      <!--begin::Preview-->
      <div
        class="m-demo"
        data-code-preview="true"
        data-code-html="true"
        data-code-js="false"
      >
        <div class="m-demo__preview b-0">
          <ul
            v-if="showMenuItem(item)"
            v-for="(item, index) in items"
            :key="index"
            class="m-nav"
          >
            <li class="m-nav__item">
              <router-link
                :to="{ name: item.link, params: { id: item.id } }"
                class="m-nav__link"
              >
                <span class="m-nav__link-bullet">
                  <span></span>
                </span>
                <span :class="`m-nav__link-text ${menuActive(item.link)}`">{{
                  item.name
                }}</span>
              </router-link>
            </li>
          </ul>

          <ul class="m-nav" v-if="$authorization.can('service-campaign-list')">
            <li class="m-nav__item">
              <router-link
                :to="{
                  name: 'service.campaign',
                  params: { id: $route.params.id },
                }"
                class="m-nav__link"
              >
                <span class="m-nav__link-bullet">
                  <span></span>
                </span>
                <span
                  :class="`m-nav__link-text ${menuActive('service.campaign')}`"
                  >Campanhas</span
                >
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Preview-->
    </div>
  </div>

  <!--end::Section-->
</template>
<script>
import Support from "../support";
import ServiceService from "../services/ServiceService";

export default {
  name: "MenuService",
  props: ["service"],
  data() {
    return {
      serviceService: new ServiceService(),
      support: Support,
      items: [
        {
          name: "Informações Básicas",
          link: "service.basic-info",
          id: this.$route.params.id
        },
        {
          name: "Ofertas",
          link: "service.offers",
          id: this.$route.params.id
        },
        {
          name: "Ativação",
          link: "service.activation",
          id: this.$route.params.id
        },
        {
          name: "Uso",
          link: "service.usage",
          id: this.$route.params.id
        },
        {
          name: "Cancelamento",
          link: "service.cancellation",
          id: this.$route.params.id
        },
        {
          name: "Keywords",
          link: "service.keywords",
          id: this.$route.params.id
        },
        {
          name: "Mensagens",
          link: "service.messages",
          id: this.$route.params.id
        },
        {
          name: "Sharecodes",
          link: "service.sharecodes",
          id: this.$route.params.id
        },
        {
          name: "Documentos",
          link: "service.storage",
          id: this.$route.params.id
        },
        {
          name: "Histórico",
          link: "service.history",
          id: this.$route.params.id
        }
        // {
        // 	name: "Campanhas",
        // 	link: "service.campaign",
        // 	id: this.$route.params.id
        // }
      ]
    };
  },

  watch: {
    serviceService: function(newValue, oldValue) {
      return this.serviceService;
    }
  },

  methods: {
    showMenuItem(item) {
      switch (item.link) {
        case "service.storage":
          return this.$authorization.can("documents");
        case "service.history":
          return this.$authorization.can("service-history");
        default:
          return true;
      }
    },
    menuActive(link) {
      return link === this.$route.name ? "menu-active" : "";
    }
  }
};
</script>

<style>
.b-0 {
  border: 0 !important;
}
.text-center {
  text-align: center;
}
.menu-active {
  color: #f4516c !important;
}
</style>


