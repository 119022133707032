export default {
  registerBadge (status) {
    const statusList = {
      'updating': {
        name: 'em atualização',
        color: 'info',
      },
        
      'under-analysis': {
        name: 'aguardando uotz',
        color: 'focus',
      },
        
      'under-analysis-claro': {
        name: 'aguardando claro',
        color: 'warning',
      },
        
      'complete': {
        name: 'completo',
        color: 'success',
      },
        
      'disapproved': {
        name: 'reprovado',
        color: 'primary',
      },
        
      'active': {
        name: 'Ativo',
        color: 'succes',
      },
        
      'inactive': {
        name: 'Desativado',
        color: 'primary',
      },
        
      'approved': {
        name: 'aprovado',
        color: 'success',
      },

      'disabled': {
        name: 'Desativado',
        color: 'primary',
      },

      'default': {
        name: 'desconhecido',
        color: 'metal',
      },
    };

    if (typeof statusList[status] === 'undefined') {
      return statusList['default'];
    }
    
    return statusList[status];
  },

  statusBadge (status) {
    const statusList = {
      'setup': {
        name: 'setup',
        color:  'dark',
      },

      'online': {
        name: 'em comercialização',
        color:  'success',
      },

      'homolog': {
        name: 'em homologação',
        color:  'accent',
      },

      'disabled': {
        name: 'desativado',
        color:  'primary',
      },

      'legacy': {
        name: 'legado',
        color:  'accent',
      },

      'suspended': {
        name: 'suspenso',
        color:  'warning',
      },

      'discontinued': {
        name: 'descontinuado',
        color:  'metal',
      },

      'integration': {
        name: 'integração',
        color:  'primary',
      },

      'online-billing': {
        name: 'Em comercialização (Carrier Billing)',
        color:  'focus',
      },

     'default': {
        name: 'desconhecido',
        color:  'metal',
      }
    };


    if (typeof statusList[status] === 'undefined') {
      return statusList['default'];
    }
    
    return statusList[status];
  },

  companyIntegrationEnvironment (environment) {
    switch (environment) {
      case 'production':
        return 'Produção';
      case 'pre-production':
        return 'Pré Produção';
    }
  }
};
