export default class FlowService {
  constructor() {
    this.axios = axios;
  }

  /**
   * Disapprove service. Only change status register
   * @param id
   * @param data
   * @returns {*|AxiosPromise<T>}
   */
  disapprove (id, data) {
    const endpoint = `service/${id}/flow/disapprove`;
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return this.axios.post(endpoint, data, options);
  }

  sendAnalysis(id) {
    return this.axios.put(`service/${id}/flow/send/analysis`);
  }
}