<template>
  <div id="service-header">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Dados Cadastrais
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link :to="{ name: 'services' }" class="m-nav__link">
                <span class="m-nav__link-text">Gestão de Serviços</span>
              </router-link>
            </li>
            <li v-show="service.name" class="m-nav__separator">-</li>
            <li v-show="service.name" class="m-nav__item">
              <span class="m-nav__link-text">{{ service.name }}</span>
            </li>
            <li v-show="service.name" class="m-nav__separator">-</li>
            <li v-show="service.name" class="m-nav__item">
              <span class="m-nav__link-text">{{ currentTab }}</span>
            </li>
          </ul>
        </div>
        <slot name="action-keyword"></slot>
        <slot name="actions" v-if="hasActions && showActions">
          <!-- PROFILE UOTZ -->
          <div
            v-if="$store.state.auth.user.profile === 1"
            class="m-btn-group m-btn-group--pill btn-group"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <button
              v-if="
                !$store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="save"
              type="button"
              :class="
                `m-btn btn btn-info ${
                  isSaving ? 'm-loader m-loader--light m-loader--right' : ''
                } `
              "
            >
              <i v-show="!isSaving" class="la la-save"></i>
              {{ !isSaving ? "Salvar" : "Salvando..." }}
            </button>

            <button
              v-if="
                $store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="editForm"
              type="button"
              :class="`m-btn btn btn-info m-btn--pill`"
            >
              <i class="la la-edit"></i>
              Editar
            </button>

            <button
              v-if="
                $authorization.can('service-approve') &&
                  service.status_register === 'under-analysis'
              "
              data-target="#approve-service"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-success"
              v-b-tooltip.hover="'Aprovar serviço'"
            >
              <i class="la la-thumbs-up"></i>
            </button>

            <button
              v-if="
                $authorization.can('service-reprove') &&
                  (service.status_register === 'under-analysis' ||
                    service.status_register === 'under-analysis-claro')
              "
              data-target="#disapprove-service"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-primary"
              v-b-tooltip.hover="'Reprovar serviço'"
            >
              <i class="la la-thumbs-down"></i>
            </button>

            <div class="m-btn-group btn-group" role="group">
              <button
                v-if="$authorization.can('service-update-status')"
                id="btnGroupDrop1"
                type="button"
                v-b-tooltip.hover="'Mais opções'"
                class="btn btn-secondary m-btn m-btn--pill-last"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="la la-ellipsis-h"></i>
              </button>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                <a
                  class="dropdown-item"
                  href="#status-service"
                  data-toggle="modal"
                  >Alterar Status</a
                >
                <a
                  class="dropdown-item text-primary"
                  href="#disabled-service"
                  data-toggle="modal"
                  >Desativar Serviço</a
                >
                <a
                  v-if="$authorization.can('service-download-json')"
                  class="dropdown-item"
                  :href="`${baseURL}/service/${service.id}/json/download`"
                  target="_blank"
                  >Download JSON</a
                >
              </div>
            </div>
          </div>

          <!-- PROFILE CLARO -->
          <div
            v-else-if="$store.state.auth.user.profile === 2"
            class="m-btn-group m-btn-group--pill btn-group"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <button
              v-if="
                !$store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="save"
              type="button"
              :class="
                `m-btn btn btn-info ${
                  isSaving ? 'm-loader m-loader--light m-loader--right' : ''
                } ${
                  service.status_register !== 'under-analysis-claro'
                    ? 'm-btn--pill'
                    : ''
                }`
              "
            >
              <i v-show="!isSaving" class="la la-save"></i>
              {{ !isSaving ? "Salvar" : "Salvando..." }}
            </button>

            <button
              v-if="
                $store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="editForm"
              type="button"
              :class="`m-btn btn btn-info m-btn--pill`"
            >
              <i class="la la-edit"></i>
              Editar
            </button>

            <button
              v-if="
                $authorization.can('service-approve') &&
                  service.status_register === 'under-analysis-claro'
              "
              data-target="#approve-service"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-success"
              v-b-tooltip.hover="'Aprovar serviço'"
            >
              <i class="la la-thumbs-up"></i>
            </button>

            <button
              v-if="
                $authorization.can('service-reprove') &&
                  service.status_register === 'under-analysis-claro'
              "
              data-target="#disapprove-service"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-primary"
              v-b-tooltip.hover="'Reprovar serviço'"
            >
              <i class="la la-thumbs-down"></i>
            </button>

            <div class="m-btn-group btn-group" role="group">
              <button
                v-if="$authorization.can('service-update-status')"
                id="btnGroupDrop2"
                type="button"
                v-b-tooltip.hover="'Mais opções'"
                class="btn btn-secondary m-btn m-btn--pill-last"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="la la-ellipsis-h"></i>
              </button>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="btnGroupDrop2"
              >
                <a
                  class="dropdown-item"
                  href="#status-service"
                  data-toggle="modal"
                  >Alterar Status</a
                >
                <a
                  class="dropdown-item text-primary"
                  href="#disabled-service"
                  data-toggle="modal"
                  >Desativar Serviço</a
                >
                <a
                  v-if="$authorization.can('service-download-json')"
                  class="dropdown-item"
                  :href="`${baseURL}/service/${service.id}/json/download`"
                  target="_blank"
                  >Download JSON</a
                >
              </div>
            </div>
          </div>

          <!-- PROFILE PARTNER -->
          <div
            v-else
            :class="`m-btn-group btn-group`"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <button
              v-if="
                !$store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="save"
              type="button"
              :class="
                `m-btn btn btn-info m-btn--pill ${
                  isSaving ? 'm-loader m-loader--light m-loader--right' : ''
                } `
              "
            >
              <i v-show="!isSaving" class="la la-save"></i>
              {{ !isSaving ? "Salvar" : "Salvando..." }}
            </button>

            <button
              v-if="
                $store.state.service.lock &&
                  $authorization.can('service-update')
              "
              @click="editForm"
              type="button"
              :class="`m-btn btn btn-info m-btn--pill`"
            >
              <i class="la la-edit"></i>
              Editar
            </button>

            <button
              v-if="
                service.status_register != 'under-analysis' &&
                  $authorization.can('service-send-analysis')
              "
              data-toggle="modal"
              data-target="#update-service"
              class="m-btn btn btn-secondary m-btn--pill"
              v-b-tooltip.hover="'Enviar informações para análise'"
            >
              <i class="fa fa-paper-plane mr-2"></i>
              Enviar para análise
            </button>

            <div class="m-btn-group btn-group" role="group">
              <button
                v-if="$authorization.can('service-update-status') || $authorization.can('service-download-json')"
                id="btnGroupDrop1"
                type="button"
                v-b-tooltip.hover="'Mais opções'"
                class="btn btn-secondary m-btn m-btn--pill-last"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="la la-ellipsis-h"></i>
              </button>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                <a
                  v-if="$authorization.can('service-download-json')"
                  class="dropdown-item"
                  :href="`${baseURL}/service/${service.id}/json/download`"
                  target="_blank"
                  >Download JSON</a
                >
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <u-modal title="Salvar Informações" id="update-service">
      <div slot="body">
        <p>Você deseja realmente enviar as informações para análise?</p>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          @click="sendAnalysis"
          type="button"
          class="btn btn-success"
          data-dismiss="modal"
        >
          Sim! Enviar para análise.
        </button>
      </div>
    </u-modal>
    <u-modal title="Aprovar Serviço" id="approve-service">
      <div slot="body">
        <p>Você deseja realmente aprovar este serviço?</p>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          @click="approve"
          type="button"
          class="btn btn-success"
          data-dismiss="modal"
        >
          Sim
        </button>
      </div>
    </u-modal>
    <u-modal title="Reprovar Serviço" id="disapprove-service" size="lg">
      <div slot="body">
        <div class="form-group">
          <label>Motivo da reprovação do serviço</label>
          <textarea
            v-model="disapprove.description"
            class="form-control"
            rows="5"
          ></textarea>
        </div>
        <div class="form-group">
          <label>Anexos</label>
          <input
            type="file"
            ref="disapproveFiles"
            @change="disapproveSelectFiles($event)"
            class="form-control"
            multiple
          />
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          v-show="!disapprove.loading"
          @click="disapproveService"
          type="button"
          class="btn btn-primary"
        >
          Reprovar
        </button>
        <button
          v-show="disapprove.loading"
          type="button"
          class="btn btn-primary m-loader m-loader--light m-loader--right"
        >
          Reprovando...
        </button>
      </div>
    </u-modal>
    <u-modal title="Alterar Status" id="status-service" size="md">
      <div slot="body">
        <div class="form-group">
          <label>Status do Cadastro</label>
          <select class="form-control" v-model="service.status_register">
            <option value="updating">Em atualização</option>
            <option value="under-analysis">Aguardando uotz</option>
            <option value="under-analysis-claro">Aguardando claro</option>
            <option value="disapproved">Reprovado</option>
            <option value="complete">Completo</option>
            <option value="disabled" disabled>Desativado</option>
          </select>
        </div>
        <div class="form-group">
          <label>Status do Serviço</label>
          <select class="form-control" v-model="service.status">
            <option value="setup">Setup</option>
            <option value="integration">Integração</option>
            <option value="homolog">Em homologação</option>
            <option value="online">Em comercialização</option>
            <option value="online-billing">
              Em comercialização (Carrier Billing)
            </option>
            <option value="legacy">Legado</option>
            <option value="suspended">Suspenso</option>
            <option value="discontinued">Descontinuado</option>
          </select>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          @click="updateAllStatus"
          type="button"
          class="btn btn-info"
          data-dismiss="modal"
        >
          Atualizar
        </button>
      </div>
    </u-modal>
    <u-modal title="Desativar Status" id="disabled-service" size="md">
      <div slot="body">
        <p>Deseja realmente desativar este serviço?</p>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Cancelar
        </button>
        <button
          @click="disable"
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
        >
          Sim! Desativar
        </button>
      </div>
    </u-modal>
  </div>
</template>

<script>
import UModal from "@/components/UModal";
import ServiceService from "../services/ServiceService";
import FlowService from "../services/FlowService";

export default {
  name: "header-service",
  components: { UModal },
  props: {
    showActions: {
      type: Boolean,
      default: true
    },

    devicesPictures: {
      type: Object,
      default() {
        return {
          desktop: null,
          tablet: null,
          mobile: null
        };
      }
    },

    files: {
      default() {
        return [];
      }
    },

    service: {
      type: Object,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    actionScopes: {
      type: Array,
      default: () => {
        return ["service-send-analysis", "service-update", "service-download-json"];
      }
    }
  },
  computed: {
    hasActions() {
      // let show = false;
      // this.actionScopes.forEach(scope => {
      //   show = this.$authorization.can(scope);
      //   if (!show) {
      //     return;
      //   }
      // });
      // return show;
      return this.actionScopes.some(scope => this.$authorization.can(scope));
    }
  },
  watch: {
    "service.status_register": function() {
      if (this.service.status_register !== "updating") {
        this.$store.commit("setLock", true);
      }
    }
  },
  data() {
    return {
      serviceService: new ServiceService(),
      flowService: new FlowService(),
      disapprove: {
        loading: false,
        description: "",
        files: []
      },
      baseURL: baseURL
    };
  },
  methods: {
    async save() {
      await this.$emit("saving");
      await this.uploadDeviceFiles();
      await this.uploadFiles();
      await this.$emit("resetFiles");
    },

    editForm() {
      swal({
        title: "Atenção!",
        type: "warning",
        text:
          "Deseja realmente alterar o cadastro deste serviço? O status do serviço será modificado e o mesmo deverá ser enviado novamente para análise.",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "some-class",
        cancelButtonClass: "some-other-class",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          return axios
            .put(`service/${this.service.id}/unlock`, {
              status: this.service.status,
              status_register: "updating"
            })
            .then(response => {
              this.$router.go();
            })
            .catch(err => {
              console.log(err);
            });
        }

        return this.$store.commit("setLock", true);
      });
    },
    send() {
      this.$emit("saving");
      this.$emit("send-to-analysis");
    },

    async uploadDeviceFiles() {
      const formData = new FormData();

      Object.keys(this.devicesPictures).forEach(key => {
        if (this.devicesPictures[key]) {
          formData.append(key, this.devicesPictures[key]);
        }
      });

      if (Array.from(formData).length) {
        toastr.clear();
        toastr.info("Salvando imagens do serviço...");

        await this.serviceService
          .storeDisplayFile(this.service.id, formData)
          .then(response => {
            toastr.clear();
          })
          .catch(error => {
            console.log(error.response);
            swal(
              "Ops!",
              "Dados Inválidos. Verifique os dados informados e tente novamente.",
              "error"
            );
          });
      }
    },

    async uploadFiles() {
      const formData = new FormData();

      Object.keys(this.files).forEach(key => {
        formData.append("files[]", this.files[key]);
      });

      if (Array.from(formData).length) {
        toastr.clear();
        toastr.info("Salvando imagens do uso serviço...");

        await this.serviceService
          .storeFiles(this.service.id, formData)
          .then(response => {
            toastr.clear();
          })
          .catch(error => {
            console.log(error.response);
            swal(
              "Ops!",
              "Dados Inválidos. Verifique os dados informados e tente novamente.",
              "error"
            );
          });
      }
    },
    approve() {
      toastr.clear();
      toastr.info("Salvando...");
      this.serviceService
        .approve(this.service.id)
        .then(response => {
          this.service.status = "approved";
          toastr.clear();
          swal("Sucesso!", "Serviço aprovado com sucesso.", "success");
        })
        .catch(error => {
          console.log(error.response);
          swal(
            "Ops!",
            "Dados Inválidos. Verifique os dados informados e tente novamente.",
            "error"
          );
        });
    },
    updateAllStatus() {
      toastr.clear();
      toastr.info("Salvando...");
      this.serviceService
        .updateAllStatus(this.service)
        .then(response => {
          toastr.clear();
          swal(
            "Sucesso!",
            "Status do serviço atualizado com sucesso.",
            "success"
          );
        })
        .catch(error => {
          console.log(error.response);
          swal(
            "Ops!",
            "Dados Inválidos. Verifique os dados informados e tente novamente.",
            "error"
          );
        });
    },
    disable() {
      toastr.clear();
      toastr.info("Salvando...");
      this.serviceService
        .disable(this.service.id)
        .then(response => {
          this.service.status_register = "disabled";
          toastr.clear();
          swal("Sucesso!", "Serviço desativado com sucesso.", "success");
        })
        .catch(error => {
          console.log(error.response);
          swal(
            "Ops!",
            "Dados Inválidos. Verifique os dados informados e tente novamente.",
            "error"
          );
        });
    },
    sendAnalysis() {
      toastr.clear();
      toastr.info("Enviando...");
      this.flowService
        .sendAnalysis(this.service.id)
        .then(response => {
          toastr.clear();
          swal("Sucesso!", "Serviço enviado para análise.", "success");
          this.service.status_register = "under-analysis";
        })
        .catch(error => {
          console.log(error.response);
          swal(
            "Ops!",
            "Ocorreu um erro ao enviar o serviço para análise, tente mais tarde.",
            "error"
          );
        });
    },
    disapproveSelectFiles(event) {
      this.disapprove.files = event.target.files;
    },
    disapproveService() {
      const id = this.service.id;
      const data = new FormData();

      data.append("description", this.disapprove.description);
      if (this.disapprove.files.length > 0) {
        for (let i = 0; i < this.disapprove.files.length; i++) {
          data.append("files[]", this.disapprove.files[i]);
        }
      }
      this.disapprove.loading = true;

      if (!this.disapprove.description.length > 0) {
        this.disapprove.loading = false;
        swal("Ops!", "Informe o motivo para reprovar este serviço.", "error");
        return;
      }

      this.flowService
        .disapprove(id, data)
        .then(response => {
          this.service.status_register = "disapproved";
          $("#disapprove-service").modal("hide");
          this.service.status_register = "disapproved";
          this.disapprove.loading = false;
          this.disapprove.description = "";
          this.disapprove.files = [];
          swal(
            "Serviço Reprovado",
            "Foi enviada uma notificação por e-mail informando o status do serviço",
            "success"
          );
        })
        .catch(error => {
          swal(
            "Ops!",
            "Não foi possível reprovar o serviço. Verifique os dados informados e tente novamente.",
            "error"
          );
          this.disapprove.loading = false;
        });
    }
  }
};
</script>
