<template>
  <div class="modal fade show" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div :class="`modal-dialog modal-${size}`" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'u-modal',
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Título do Modal'
    },
    size: {
      type: String,
      default: 'md',
    }
  },
}
</script>

