/* global axios */
import store from "@/store";

export default class ServiceService {
  constructor() {
    this.axios = axios;
  }

  all(page, filter = "", companyId = null) {
    let url = `service?page=${page}`;
    url += filter.service ? `&service=${filter.service.value}` : "";
    url += filter.company ? `&company=${filter.company.value}` : "";
    url += companyId ? `&company_id=${companyId}` : "";
    url += filter.responsible ? `&responsible=${filter.responsible.value}` : "";
    url += filter.category ? `&category=${filter.category.value}` : "";
    url += filter.status ? `&status=${filter.status.value}` : "";
    url += filter.sharecode ? `&sharecode=${filter.sharecode}` : "";
    url += filter.statusRegister
      ? `&statusRegister=${filter.statusRegister.value}`
      : "";
    return this.axios.get(url);
  }

  getServiceHistory(id) {
    return this.axios.get(`service/${id}/history`);
  }

  getService(id) {
    return this.axios.get(`service/${id}`);
  }

  store(service) {
    return this.axios.post("service", {
      name: service.name,
      company_id: service.company_id
    });
  }

  async save(service) {
    return await this.axios.put(`service/${service.id}`, service);
  }

  destroy(service) {
    return this.axios.delete(`service/${service.id}`, service);
  }

  approve(id) {
    return this.axios.put(`service/${id}/approve`);
  }

  disable(id) {
    return this.axios.put(`service/${id}/disable`);
  }

  updateAllStatus(service) {
    return this.axios.put(`service/${service.id}/update/status`, {
      status: service.status,
      status_register: service.status_register
    });
  }

  storeDisplayFile(serviceID, devices) {
    return this.axios.post(`service/${serviceID}/files/display`, devices, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  storeFiles(serviceID, files) {
    return this.axios.post(`service/${serviceID}/files`, files, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}
